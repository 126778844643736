const settings = {
    defaultLanguage: 'ro',
    API_URL: 'https://api.radugalan.com/',
    API_ENDPOINT_REGISTER_YEAR: 'users/register/years',
    API_ENDPOINT_REGISTER:'users/register',
    API_ENDPOINT_LOGIN: 'users/login',
    API_ENDPOINT_UPDATE_PROFILE: 'account',
    API_ENDPOINT_MY_ACCOUNT: 'users/account',
    API_ENDPOINT_UPLOAD_IMAGES: 'art/images',
    API_ENDPOINT_UPLOAD_VIDEOS: 'art/videos',
    API_ENDPOINT_SEARCH_IMAGES_OLDER: 'art/search/images/older/',
    API_ENDPOINT_SERCH_VIDEOS_OLDER: 'art/search/videos/older/',
    API_ENDPOINT_UPLOAD_IMAGES_WITHOUT_VARIATIONS: "art/images/without/variants",
    API_ENDPOINT_FILE: 'art/file/image/',
    API_ENDPOINT_VIDEO_FILE: 'art/file/video/',
    API_ENDPOINT_FILE_FULL: 'file',
    API_ENDPOINT_SEARCH_IMAGES: 'art/search/images',
    API_ENDPOINT_MY_IMAGES: 'art/my/images',
    API_ENDPOINT_MY_VIDEOS: 'art/my/videos',
    API_ENDPOINT_VIDEOS: 'art/videos',
    API_ENDPOINT_SEARCH_VIDEOS: 'art/search/videos',
    API_ENDPOINT_VIDEOS_PAGINATED: 'art/paginated/videos',
    API_ENDPOINT_USER_IMAGES: 'art/user/images/',
    API_ENDPOINT_PUT_IMAGES: 'art/images/',
    API_ENDPOINT_PUT_VIDEOS: 'art/videos/',
    API_ENDPOINT_IMAGES_VARIANT: 'art/images/variants/',
    API_ENDPOINT_SHOPPINGCART: 'art/shoppingcart',
    API_ENDPOINT_SHOPPINGCART_MERGE: 'art/shoppingcart/merge/',
    API_ENDPOINT_SHOPPINGCARTITEMS: 'art/shoppingcartitems/',
    API_ENDPOINT_COUNTIES: 'art/counties',
    API_ENDPOINT_ADDRESS: 'art/address',
    API_ENDPOINT_ORDER: 'art/order/',
    API_ENDPOINT_ORDERS: 'art/orders',
    API_ENDPOINT_ORDERS_STATUSES: 'art/orders/statuses',
    API_ENDPOINT_ORDERS_INVOICE_ADDRESS: "art/order/invoice/address/",
    API_ENDPOINT_ORDERS_DELIVERY_ADDRESS: "art/order/delivery/address/",
    API_ENDPOINT_ORDER_ITEMS: 'art/orders/items/',
    API_ENDPOINT_USER_REWARDS: "art/user/rewards/current",
    API_ENDPOINT_USER_PAYMENTS: 'art/user/payments',
    API_ENDPOINT_ADMIN_PAYMENTS: 'art/admin/payments',
    API_ENDPOINT_USER_RECOVER_PASSWORD: 'users/recover',
    API_ENDPOINT_USER_RECOVER_PASSWORD_NEW: 'users/recover/password',
    API_ENDPOINT_USER_ORDERS: 'art/orders/user',
    API_ENDPOINT_PAYMENTS_STATUSES: 'art/payments/statuses',
    API_ENDPOINT_PAYMENTS: 'art/payments/',
    API_ENDPOINT_USER_ORDER_INVOICE_ADDRESS: 'art/user/order/invoice/address/',
    API_ENDPOINT_USER_ORDER_DELIVERY_ADDRESS: 'art/user/order/delivery/address/',
    API_ENDPOINT_SHOPPINGCART_TOTAL: 'art/shoppingcart/total/',
    SETTING_ACCESS_LEVEL_PHOTOGRAPHER: 98,
    SETTING_ACCESS_LEVEL_ORDER_PROCESSER: 97,
    API_ENDPOINT_DELIVERY_METHODS: 'art/delivery/methods',
    SETTING_SITE_URL: "https://www.radugalan.com",
    SOCIAL_MEDIA_SETTING_SITE_URL: "https://l.radugalan.com/",
    API_ENDPOINT_PATH_VIDEO_URL: "https://api.radugalan.com/file/thumb/png"
}

export default settings;
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Settings from '../../Settings/Settings'
import LinkIcon from '@material-ui/icons/Link';
import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));

export default function TitlebarImageList(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const getPictureUrl = (imagePath) => {
    if(window.location.pathname === '/') {
      return '/image/' + imagePath
    } else {
      let concatString = '/'
      if (window.location.pathname.slice(-1) === '/') {
        concatString = ''
      }
      return window.location.pathname + concatString + 'image/' + imagePath
    }
  }

  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }

  return (
        <ImageListItem style={{width:350, height: 350, margin:'8px auto', backgroundColor:'#000', boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'}}>
      <Link to={getPictureUrl(props.element.imagesPath) }>
            <img style={{margin:"0 auto",display:"inline-block",objectFit:"cover", width:350, height:350}} src={Settings.API_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath} alt={props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName + " "+props.element.imagesLocationName + " " + props.element.imagesDescription} />
        </Link>
        <ImageListItemBar
            title={props.element.imagesLocationName + " " + props.element.imagesDescription}
            subtitle={<span>{props.element.usersFirstName + " " + props.element.usersLastName}</span>}
            actionIcon={
                <IconButton color="secondary" aria-label="share" onClick={() => {
                navigator.clipboard.writeText(Settings.SOCIAL_MEDIA_SETTING_SITE_URL + 'image/' + props.element.imagesPath)
                componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                }}>
                <LinkIcon />
                </IconButton>
            }
        />
        </ImageListItem>
  );
}
